'use client'

import { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import '@/sass/menu/MenuNotFound.scss'
import { barlowCondensed } from '@/lib/fonts'

export default function NotFound () {
  const [message, setMessage] = useState({
    title: 'Upss!',
    description: 'La página que busca no existe.',
    home: 'Inicio'
  })

  useEffect(() => {
    const language = navigator.language
    if (language.startsWith('es')) {
      setMessage({
        title: 'Upss!',
        description: 'La página que busca no existe',
        home: 'Volver a casa'
      })
    } else {
      setMessage({
        title: 'Oops!',
        description: 'The page you are looking for does not exist.',
        home: 'Go back Home'
      })
    }
  }, [])

  return (
    <html>
      <body className={barlowCondensed.className}>
        <div style={barlowCondensed.style}>
          <div className='menu-not-found'>
            <Image
              src='https://trivify.es/barmanager/404'
              alt='not found'
              width={300}
              height={300}
            />
            <p>{message.title}</p>
            <p>{message.description}</p>
            <Link href='/'>{message.home}</Link>
          </div>
        </div>
      </body>
    </html>
  )
}
