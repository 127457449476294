import { Allan, Barlow_Condensed, Caesar_Dressing, Comfortaa, Dancing_Script, Edu_NSW_ACT_Foundation, Englebert, Grenze_Gotisch, Hind_Madurai, Inter, Love_Ya_Like_A_Sister, Oregano, Ranga, Roboto, Snippet, Stick_No_Bills, Sunshiney, Teko, Truculenta } from 'next/font/google'

export const barlowCondensed = Barlow_Condensed({ subsets: ['latin'], weight: ['400', '600'], display: 'swap' })
export const teko = Teko({ subsets: ['latin'], weight: '400', display: 'swap' })
export const comfortaa = Comfortaa({ subsets: ['latin'], weight: ['400', '600'], display: 'swap' })
export const inter = Inter({ subsets: ['latin'], weight: ['400', '800'], display: 'swap' })
export const roboto = Roboto({ subsets: ['latin'], weight: ['400', '700'], display: 'swap' })

// Para personalización
export const truculenta = Truculenta({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const loveYa = Love_Ya_Like_A_Sister({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const hindMadurai = Hind_Madurai({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const grenze = Grenze_Gotisch({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const edu = Edu_NSW_ACT_Foundation({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const caesarDressing = Caesar_Dressing({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const dancingScript = Dancing_Script({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const stickNoBills = Stick_No_Bills({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const allan = Allan({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const oregano = Oregano({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const sunshiney = Sunshiney({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const snippet = Snippet({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const ranga = Ranga({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })
export const englebert = Englebert({ subsets: ['latin'], weight: '400', display: 'swap', preload: false })

export type FontId = 'barlowCondensed' | 'truculenta' | 'loveYa' | 'hindMadurai' | 'grenze' | 'edu' | 'caesarDressing' | 'dancingScript' | 'stickNoBills' | 'allan' | 'oregano' | 'sunshiney' | 'snippet' | 'ranga' | 'englebert';

export const menuFonts = {
  barlowCondensed,
  truculenta,
  loveYa,
  hindMadurai,
  grenze,
  edu,
  caesarDressing,
  dancingScript,
  stickNoBills,
  allan,
  oregano,
  sunshiney,
  snippet,
  ranga,
  englebert
}
